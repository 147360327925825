/* src/App.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&display=swap');
body {
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
  background-color: rgba(247, 247, 247, 1);
}

html {
  overscroll-behavior: none;
}

.App {
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
  
  width: 100vw;
}

h1 {
  color: #333;
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 20px;
}
.appBar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  flex-wrap: wrap;
}
/* Default: hide the h1 tag on mobile */
.hide-on-mobile {
    display: none;
}

/* Show the h1 tag on desktop */
@media (min-width: 768px) {
    .hide-on-mobile {
        display: block;
    }
}

.feed-list {
 
}
.feed {
  margin: auto;
  max-width: 800px;
  padding: 0 16px;
}

.image {
  width: 90%;
  border-radius: 20px;
}

@media (min-width: 768px) {
    .feed {
      width: 70%
    }
    .image {
      width: 60%;
    }
  }

.item {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }


.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.viewLink {
  font-size: 10pt;
}


.h5 {
  margin: 10px 0 0;
  color: #222;
  text-align: right;
}

p {
  
  color: #333;
}

a {
  color:  rgba(0, 0, 0, 0.805);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.link-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

button {
  padding: 10px 15px;
  background-color: rgba(0, 195, 134, 0.805);
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

button:hover {
 
}

.embed-card {
  border: 0px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 12px;
}

.embed-card img {
  width: 100%;
  height: auto;
}

.embed-content {
  background-color: rgba(190, 190, 190, 0.189);
  padding: 10px;
}

.embed-content h3 {
  margin: 0;
  font-size: 1.1em;
}

.embed-content p {
  margin: 5px 0 0;
  font-size: 0.9em;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
}

.appTitle {
  font-size: 18pt;
  margin: 0 20px;
  white-space: nowrap;
}

@media (min-width: 768px) {
    .my-div {
        width: 50%;
        padding: 40px;
    }
    .appTitle {
      font-size: 22pt;
    }
}

.tabs button {
  background: none;
  color: black;

  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
  margin: 10px;
}

.tabs button.active {
  background-color: rgba(0, 195, 134, 0.836);
  color: white;
}

.tabs button:not(.active):hover {
  background-color: #f1f1f1;
}

.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  background-color:  rgba(0, 195, 134, 0.805);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; /* Allows the modal to be scrollable if content overflows */
}

/* Modal content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto; /* Enables vertical scrolling inside the modal */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content button {
  margin: 10px;
  padding: 10px 20px;
  background-color:  rgba(0, 195, 134, 0.805);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button.close {
  background-color: #e0e0e0;
  color: #000;
}

.source-category {
  margin-bottom: 20px;
}

.source-category h3 {
  margin-bottom: 10px;
}

.source-links {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: space-between;
}

.source-links button {
  flex: 1 1 ; /* Three buttons per row with spacing */
  margin-bottom: 5px;
  padding: 10px;
  background-color: rgba(0, 195, 134, 0.805);
  color:  white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.buttons button {

  padding: 10px;
  background-color: rgba(0, 195, 134, 0.805);
  color:  white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.networks button {

  padding: 10px;
  background-color: #f0f0f0;
  color:  black;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  margin: 10px;
}
.networks {
  text-align: center;
  margin: auto;
}

/* Add these styles to your existing App.css file if you haven't already */

.network-button-container {
  position: relative;
  display: inline-block;
}

.network-button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.network-button.active {
  background-color: rgba(0, 195, 134, 0.793);
  color: white;
}

.tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.network-button-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip a {
  color: #4CAF50;
  text-decoration: none;
}

.tooltip a:hover {
  text-decoration: underline;
}

.bookmark-button {
  float: right;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #ccc;
}

.bookmark-button.bookmarked {
  color: gold;
}

.navigation {
  display: flex;
  justify-content: center;
  margin: auto;
}

.navigation button {
  border: none;
  color: black;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 20px;
  margin: 0 5px;
  padding: 10px;
  border: none;

  transition: background-color 0.3s;
}

.navigation button.active {
  background-color: rgba(0, 195, 134, 0.793);
  color: white;
  border-radius: 20px;

}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid rgba(0, 195, 134, 0.805);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Fade-in animation for the app content */
.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Post Content */
.post-content {
  margin: 12px 0;
  font-size: 15px;
  line-height: 1.5;
  color: #1a1a1a;
}

/* Link Preview Card */
.link-preview {
  margin: 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.link-preview:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.link-card {
  text-decoration: none;
  color: inherit;
  display: block;
}

.link-content {
  display: flex;
  padding: 16px;
  gap: 16px;
}

.link-text {
  flex: 1;
  min-width: 0;
}

.link-title {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.4;
}

.favicon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 3px;
}

.link-description {
  margin: 0;
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.link-image {
  width: 140px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

.link-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Post Footer */
.row {
  margin-top: 12px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.viewLink {
  font-size: 13px;
  color: #666666;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.viewLink:hover {
  color: rgba(0, 195, 134, 0.9);
  text-decoration: none;
}

/* Bookmark Button */
.bookmark-button {
  padding: 8px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #cccccc;
  transition: transform 0.2s ease, color 0.2s ease;
  float: right;
  margin: -8px -8px 0 0;
}

.bookmark-button:hover {
  transform: scale(1.1);
}

.bookmark-button.bookmarked {
  color: #ffd700;
}

/* Time Display */
time {
  font-size: 13px;
  color: #666666;
  display: inline-block;
  margin-bottom: 8px;
}

/* Responsive Design */
@media (max-width: 640px) {
  .item {
    padding: 16px;
    margin-bottom: 12px;
  }

  .link-content {
    padding: 12px;
  }

  .link-image {
    width: 100px;
    height: 100px;
  }

  .link-title {
    font-size: 15px;
  }

  .link-description {
    font-size: 13px;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .item {
    background-color: #1a1a1a;
    border-color: rgba(255, 255, 255, 0.1);
  }

  .post-content {
    color: #e1e1e1;
  }

  .link-preview {
    background-color: #1a1a1a;
    border-color: rgba(255, 255, 255, 0.1);
  }

  .link-title {
    color: #e1e1e1;
  }

  .link-description {
    color: #999999;
  }

  .viewLink {
    color: #999999;
  }

  .viewLink:hover {
    color: rgba(0, 195, 134, 0.9);
  }

  time {
    color: #999999;
  }

  .row {
    border-top-color: rgba(255, 255, 255, 0.1);
  }
}

/* Header and Network Selector Styles */
.appBar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 16px 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  gap: 20px;
}

.appTitle {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  grid-column: 2;
}

.networks {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  grid-column: 3;
}

.network-button-container {
  position: relative;
  display: inline-block;
}

.network-button {
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.network-button.active {
  background-color: rgba(0, 195, 134, 0.9);
  color: white;
  border-color: transparent;
}

.tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: 13px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.network-button-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

@media (max-width: 768px) {
  .appBar {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .appTitle {
    grid-column: 1;
  }

  .networks {
    grid-column: 1;
    justify-content: center;
    flex-wrap: wrap;
  }
}